import { render, staticRenderFns } from "./ViewAppraisalRequest.vue?vue&type=template&id=1ae2eab0&"
import script from "./ViewAppraisalRequest.vue?vue&type=script&lang=ts&"
export * from "./ViewAppraisalRequest.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports